#carta {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

#carta-encabezado {
  background-image: url("../../web/img/parchment_top.png");
  min-height: 124px;
  margin-bottom: 40px;
}

#carta-contenido {
  background-image: url("../../web/img/parchment_middle.png");
  background-repeat: repeat-y;
  min-height: 50px;
  margin-top: -40px;
  padding-bottom: 40px;
  position: relative;
  top: -2px;
}

#carta-mensaje {
  text-align: left;
  color: #6f0d0d;
  font-size: 17px;
  padding: 0 70px 15px;
  margin-top: -30px;
  overflow-y: scroll;
  transition: max-height 1.4s ease 0.4s;
  max-height: 0;
}
#carta-mensaje p {
  margin-top: 15px;
  margin-bottom: 15px;
}

#carta-firma {
  text-align: right;
  color: #6f0d0d;
  font-size: 15px;
  padding-left: 79px;
  margin-bottom: 0px;
  width: 355px;
}

#carta-footer {
  height: 96px;
  background-image: url("../../web/img/parchment_bottom.png");
  margin-top: -20px;
  color: rgb(62, 45, 45);
  text-align: right;
  position: relative;
  transform: translateX(-40px);
}

#carta-mensaje,
#carta-footer {
  transition: all 2s ease-out 0.2s;
}

html.page-about.show #carta-mensaje {
  max-height: calc(100vh - 164px - 96px); /* 100vh - header - footer */
}

#carta-footer {
  transform: translateX(0);
}