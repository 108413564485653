input[type=checkbox],
input[type=radio] {
  opacity: 1;
  width: auto;
  position: static;
  height: auto;
}

select {
  border-color: #ccc !important;
}

.wh-form__label {
  display: block;
  margin-bottom: 4px;
}
.wh-form__fields {
  max-width: 600px;
}
.wh-form__group {
  margin: 0 0 30px;
}
.wh-form__fieldgroup {
  align-items: center;
  margin: 0 0 20px;
}
.wh-form__fieldgroup--error .wh-form__optionlabel {
  color: #d00;
}
.wh-form__fieldgroup--required label::after {
  content: "*";
}
.wh-form__fieldgroup .wh-form__prefix {
  margin-right: 10px;
}
.wh-form__fieldgroup .wh-form__suffix {
  margin-left: 10px;
}
.wh-form input[type=text],
.wh-form input[type=email],
.wh-form input[type=password],
.wh-form input[type=url],
.wh-form input[type=search],
.wh-form input[type=number],
.wh-form input[type=date],
.wh-form input[type=file],
.wh-form textarea {
  font-size: 16px;
  padding: 20px 10px;
  width: 100%;
  color: #000;
}
.wh-form input[type=text].parsley-error,
.wh-form input[type=email].parsley-error,
.wh-form input[type=password].parsley-error,
.wh-form input[type=url].parsley-error,
.wh-form input[type=search].parsley-error,
.wh-form input[type=number].parsley-error,
.wh-form input[type=date].parsley-error,
.wh-form input[type=file].parsley-error,
.wh-form textarea.parsley-error {
  border-color: #d00;
}
.wh-form input[type=text]::placeholder,
.wh-form input[type=email]::placeholder,
.wh-form input[type=password]::placeholder,
.wh-form input[type=url]::placeholder,
.wh-form input[type=search]::placeholder,
.wh-form input[type=number]::placeholder,
.wh-form input[type=date]::placeholder,
.wh-form input[type=file]::placeholder,
.wh-form textarea::placeholder {
  color: #cccccc;
}
.wh-form input[type=text][disabled],
.wh-form input[type=email][disabled],
.wh-form input[type=password][disabled],
.wh-form input[type=url][disabled],
.wh-form input[type=search][disabled],
.wh-form input[type=number][disabled],
.wh-form input[type=date][disabled],
.wh-form input[type=file][disabled],
.wh-form textarea[disabled] {
  background: rgba(238, 238, 238, 0.5);
  color: rgba(0, 0, 0, 0.3);
}
.wh-form input[type=number] {
  max-width: 150px;
}
.wh-form textarea {
  height: 150px;
  padding-top: 10px;
}
.wh-form select {
  height: 42px;
}
.wh-form__fieldgroup--radiogroup .wh-form__optiondata, .wh-form__fieldgroup--checkboxgroup .wh-form__optiondata, .wh-form__fieldgroup--checkbox .wh-form__optiondata {
  margin: 0 15px 0 5px;
}
.wh-form__fieldgroup--radiogroup .wh-form__subfieldlabel + input, .wh-form__fieldgroup--pulldown .wh-form__subfieldlabel + input {
  margin-left: 10px;
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline {
  margin-bottom: 8px;
}
.wh-form__buttongroup .wh-form__button {
  margin-right: 10px;
}
.wh-form .wh-form__fields,
.wh-form .wh-form__fieldgroup--imgedit .wh-form__fieldline {
  display: flex;
  flex-direction: column;
}
.wh-form .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--imgedit .wh-form__imgedit {
  order: 2;
}
.wh-form .wh-form__errorlist,
.wh-form .wh-form__fieldgroup--imgedit .parsley-errors-list {
  order: 1;
  margin: 0 0 6px;
}
.wh-form .parsley-errors-list {
  width: 100%;
  padding: 0;
  margin: 0;
  color: #d00;
  font: inherit;
  list-style-type: none;
}
.wh-form .wh-form__fieldgroup--imgedit .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--imgedit .wh-form__imgedit {
  display: inline-block;
}
.wh-form__imgedit {
  outline: none;
}
.wh-form__imgedit .wh-form__imgeditholder {
  display: inline-block;
  position: relative;
  border: 1px solid black;
  width: 100px;
  height: 100px;
}
.wh-form__imgedit .wh-form__imgeditholder::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  font-size: 24px;
  content: "\f03e";
  font-family: FontAwesome;
  height: auto;
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder {
  width: 250px;
  height: 250px;
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form__imgeditimg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.wh-form__imgeditdelete::after {
  color: #d00;
  content: "Afbeelding verwijderen";
  display: block;
  font-size: 0.8em;
  cursor: pointer;
}

/* Custom checkbox/radio */
.wh-form {
  /* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
  /* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
}
.wh-form [type=checkbox],
.wh-form [type=radio] {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.wh-form [type=checkbox]:focus + label::before,
.wh-form [type=radio]:focus + label::before {
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.4) !important;
}
.wh-form [type=checkbox]:hover + label::before,
.wh-form [type=radio]:hover + label::before {
  border-color: #000;
}
.wh-form [type=checkbox]:active + label::before,
.wh-form [type=radio]:active + label::before {
  transition-duration: 0;
}
.wh-form [type=checkbox] + label,
.wh-form [type=radio] + label {
  position: relative;
  padding: 0;
  user-select: none;
}
.wh-form [type=checkbox] + label::before,
.wh-form [type=radio] + label::before {
  background-color: #fff;
  border: 1px solid #444;
  box-sizing: content-box;
  content: "";
  color: #444;
  margin-right: 5px;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
.wh-form [type=checkbox] + label::after,
.wh-form [type=radio] + label::after {
  box-sizing: content-box;
  content: "";
  background-color: #444;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}
.wh-form [type=checkbox][disabled] + label::before,
.wh-form [type=radio][disabled] + label::before {
  animation: none;
  box-shadow: none;
  border: 1px solid rgba(128, 128, 128, 0.5);
}
.wh-form [type=checkbox][disabled]:active + label::before, .wh-form [type=checkbox][disabled]:focus + label::before, .wh-form [type=checkbox][disabled]:hover + label::before,
.wh-form [type=radio][disabled]:active + label::before,
.wh-form [type=radio][disabled]:focus + label::before,
.wh-form [type=radio][disabled]:hover + label::before {
  border-color: rgba(128, 128, 128, 0.5);
  filter: none;
  transition: none;
}
.wh-form [type=checkbox] + label::before, .wh-form [type=checkbox] + label::after {
  border-radius: 0;
}
.wh-form [type=checkbox] + label::after {
  background-color: transparent;
  top: 50%;
  left: calc(0 + 1px + 20px / 5);
  width: 10px;
  height: 4px;
  margin-top: -3px;
  border-style: solid;
  border-color: #444;
  border-width: 0 0 3px 3px;
  border-radius: 0;
  border-image: none;
  transform: rotate(-45deg) scale(0);
  transition: none;
}
.wh-form [type=checkbox]:checked + label::after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.wh-form [type=radio] + label::before, .wh-form [type=radio] + label::after {
  border-radius: 50%;
}
.wh-form [type=radio]:checked:active + label::before, .wh-form [type=radio]:checked:focus + label::before {
  animation: none;
  filter: none;
  transition: none;
}
.wh-form [type=radio]:checked + label::before {
  animation: none;
  background-color: #fff;
}
.wh-form [type=radio]:checked + label::after {
  transform: scale(1);
}
.wh-form select {
  appearance: none;
  background-color: #fff;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%228%22%20viewBox%3D%220%200%2016%208%22%3E%3Cpolygon%20fill%3D%22%234D4D4D%22%20points%3D%228%2C0%2016%2C0%2012%2C4%208%2C8%204%2C4%200%2C0%20%22/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: 95% 50%;
  border: 1px solid grey;
  border-radius: 0;
  padding: 0.25em 0.4em;
  padding-right: 1.5em;
}
.wh-form select:focus, .wh-form select:hover {
  border-color: black;
  outline: none;
}
.wh-form select:active {
  border-color: #900;
}
.wh-form select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .wh-form select::-ms-expand {
    display: none;
  }
  .wh-form select:focus::-ms-value {
    background: transparent;
    color: grey;
  }
}
.wh-form .error-msg {
  display: block;
  color: red;
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease-out;
  will-change: max-height;
}

@keyframes borderscale {}