@media screen and (min-width: 700px) {
  html.page-home body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.p-home #background {
  width: 100vw;
  max-width: 100vw;
  min-height: 500px;
  max-height: 100vh;
  display: block;
  margin-top: 50px;
  background-position-x: 38% !important;
}
.p-home #background::before, .p-home #background::after {
  content: "";
  display: table;
  clear: both;
  width: 1px;
  margin-left: -1px;
}
.p-home #background::before {
  float: left;
  padding-bottom: 50%;
}
@media (min-width: 700px) {
  .p-home #background {
    margin-top: 0;
  }
}